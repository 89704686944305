import { FC, useEffect, useState } from "react";
import { ClientProps } from "../../../../hooks/useClientData";
// import { MapsList } from "../../../../consts/MapsLIst";
import { useTimer } from "../../../../hooks/useCountDown";
// import battleBus from "../../assets/battlebus.png";
// import battleBusFade from "../../assets/battlebusFade.png";
import { ReactComponent as QuestionMarks } from "../../assets/QuestionMarks.svg";
import { ReactComponent as LeaderboardEmptyCrown } from "../../assets/LeaderboardEmptyCrown.svg";
import { brand } from "../../../../brandVariables/getBandVariables";
import { TimerComp } from "../TimerComp";
import { BrandLogo } from "../LogoComp";
export const LeaderboardIsEmpty: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const { time, startTimer, timeObj, isTimeOver, clearTimer } = useTimer(
    clientData.challenge.data?.endDate || undefined
  );

  return (
    <div className="leaderboard__empty">
      <div className="empty">
        <div className="waiting-screen__heading">
          <BrandLogo />
        </div>
        <div className="empty__meta">
          {/* <p>Fallguys challenge is here!</p> */}
          <h1>Set the best score</h1>
          <div className="empty__winPrize">
            <section>
              <h2>
                Win a {brand.prize?.nameOf}
                {brand.prize?.typeOfItem}{" "}
              </h2>
            </section>
          </div>
        </div>
        <div className="empty__questionMarks">
          <QuestionMarks />
        </div>
        <div className="empty__crown">
          <LeaderboardEmptyCrown />
        </div>
        <div>
          <TimerComp time={time} />
        </div>
      </div>
    </div>
  );
};
