import BaseUserAvatar from "@/components/reusable/BaseUserAvatar";
import { ClientProps } from "@/hooks/useClientData";
import { leaderboardUsers } from "@/types/leaderboardUsersResponse";
import { FC } from "react";
import { ScoreCard } from "../scoreCard/ScoreCard";
import { UserCard } from "../userCard/UserCard";

export const LeaderboardFull: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const leaderboard = clientData.leaderboard.data.slice(
    0,
    10
  ) as leaderboardUsers[];

  return (
    <div className="leaderboard">
      <section className="leaderboard__legend">
        <div>
          <p>#</p>
        </div>
        <div>
          <p>PLAYER</p>
        </div>
        <div>
          <p>TIME</p>
        </div>
      </section>
      <span className="leaderboard__line" />
      <section className="leaderboard__list">
        <ul className="userCards">
          {leaderboard.map((user, index) => {
            return <UserCard user={user} place={index + 1} />;
          })}
        </ul>
      </section>
    </div>
  );
};
