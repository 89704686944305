import { brand } from "@/brandVariables/getBandVariables";
import { ReactComponent as StopWatch } from "../../../assets/stopwatch.svg";
import { ReactComponent as StopWatchElgiganten } from "../assets/Timer icon elgiganten racing.svg";

export const TimerComp = ({ time, justTimer = false }: any) => {
  const isTimerOn0 = time === "00:00:00";
  const isElgiganten = brand.client === "ELGIGANTEN" ? "text-gradient" : "";

  const stopWatchToRender = () => {
    if (brand.client === "ELGIGANTEN") {
      return <StopWatchElgiganten />;
    } else {
      return <StopWatch />;
    }
  };
  return (
    <span className="timer_comp">
      {!isTimerOn0 && !justTimer && <p>Challenge ends in</p>}
      {!isTimerOn0 && <figure>{stopWatchToRender()}</figure>}

      {isTimerOn0 ? (
        <>
          <span className={`${isElgiganten}`}>Processing...</span>
        </>
      ) : (
        <span className={`${isElgiganten}`}>{time || "--:--:--"} </span>
      )}
    </span>
  );
};
