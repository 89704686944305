import { FC } from "react";
import { ClientProps } from "../../../hooks/useClientData";
import { leaderboardUsers } from "../../../types/leaderboardUsersResponse";
import BaseUserAvatar from "../../../components/reusable/BaseUserAvatar";
import { TimerComp } from "./timerComp";
import { useTimer } from "../../../hooks/useCountDown";

export const Fortnite_leaderboard: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const users = clientData.leaderboard.data?.slice(0, 4) as leaderboardUsers[];
  const length = users?.length || (0 as number);

  const { time, startTimer, timeObj, isTimeOver, clearTimer } = useTimer(
    clientData.challenge.data?.endDate || undefined
  );

  return (
    <div className="leaderboard__list">
      <div className="list">
        <div className="list__heading">
          <h1>Leaderboard</h1>
          <p>Fortnite challenge</p>
        </div>
        <div>
          <ul>
            {users.map((user, index) => {
              return (
                <li className="user">
                  <section>
                    <div className="user__rank">
                      {index !== 0 ? (
                        <p>#{index + 1}</p>
                      ) : (
                        // <div>
                        <div>
                          <p>#{index + 1}</p>
                        </div>
                        // </div>
                      )}
                    </div>
                    <div className="user__info">
                      <BaseUserAvatar
                        userName={user.profile.userName}
                        logoUrl={user.profile.profileLogoUrl}
                      />
                      <p>{user.profile.userName}</p>
                    </div>
                    <div />
                    <div className="user__score">
                      <p>{user.userChallengeData.points}</p>
                    </div>
                  </section>
                </li>
              );
            })}
            {Array.from(Array(8 - length), (_, index) => {
              return (
                <li className="user user__ghost">
                  <section>
                    <div className="user__rank">
                      {index !== 0 ? (
                        <p></p>
                      ) : (
                        // <div>
                        <div>
                          <p></p>
                        </div>
                        // </div>
                      )}
                    </div>
                    <div className="user__info">
                      <p></p>
                    </div>
                    <div />
                    <div className="user__score">
                      <p></p>
                    </div>
                  </section>
                </li>
              );
            })}
            <div>
              <TimerComp time={time} />
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};
