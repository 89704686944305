import PrizeImage from "../../assets/emptyLeaderboardPrize_Image_Angle 1.png";

export const LeaderboardIsEmpty = () => {
  return (
    <div className="leaderboard_empty">
      <div className="leaderboard_prize">
        <img src={PrizeImage} alt="" />
      </div>
    </div>
  );
};
