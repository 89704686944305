import BaseUserAvatar from "@/components/reusable/BaseUserAvatar";
import { ScoreCard } from "../scoreCard/ScoreCard";

export const TopThreeCard = ({ place, user }: { place: number; user: any }) => {
  return (
    <div className={` topThree__card topThree__card-${place}`}>
      <div className="card">
        <div className="card__place">
          <p>#{place + 1}</p>
        </div>

        <BaseUserAvatar
          userName={user.profile.userName}
          logoUrl={user.profile.profileLogoUrl}
        />
        <div className="card__username">{user.profile.userName}</div>
        <div>
          <ScoreCard place={place} score={user.userChallengeData.points} />
        </div>
      </div>
    </div>
  );
};
