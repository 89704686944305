import { FC } from "react";
import { ClientProps } from "../../../../hooks/useClientData";

export const LeaderboardHasOneScore: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const winnerData = clientData.leaderboard.data?.[0];

  return (
    <>
      <h1>LEADERBOARD 1</h1>
    </>
  );
};
