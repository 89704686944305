import { brand } from "@/brandVariables/getBandVariables";
import { ReactComponent as StopWatch } from "../assets/stopwatch_white.svg";
export const TimerComp = ({ time, justTimer = false }: any) => {
  const isTimerOn0 = time === "00:00:00";

  return (
    <span className="timer_comp">
      {!isTimerOn0 && (
        <div>
          <figure>
            <StopWatch />
          </figure>
          {isTimerOn0 ? (
            <>
              <span>Processing...</span>
            </>
          ) : (
            <span>{time || "--:--:--"} </span>
          )}
        </div>
      )}

      {!isTimerOn0 && !justTimer && <p>Challenge ends in</p>}
    </span>
  );
};
