import { FC } from "react";
import { WinnerResponse } from "../../../types/winnersResponse";
import { ReactComponent as RankRest } from "../assets/Rank_rest.svg";
import BaseUserAvatar from "../../../components/reusable/BaseUserAvatar";

export const WinnerCard: FC<{
  winner: WinnerResponse | null;
  hasLabel: boolean;
}> = ({ winner, hasLabel = true }) => {
  if (!winner) {
    return <></>;
  }

  return (
    <li className="winner">
      <section>
        {hasLabel && <label>Previous winner</label>}
        <figure>
          <RankRest />
        </figure>
        <div className="winner__info">
          <BaseUserAvatar
            userName={winner.userName}
            logoUrl={winner.userProfileLogoUrl}
          />
          <p className="winner__name">{winner.userName}</p>
        </div>
        <div className="winner__score">
          <p>{winner.points || "-"}</p>
        </div>
      </section>
    </li>
  );
};
