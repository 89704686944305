import { FC, useEffect, useState } from "react";
import { ClientProps } from "../../hooks/useClientData";
import { brand } from "../../brandVariables/getBandVariables";
import "./styles/index.scss";
import { useTimer } from "../../hooks/useCountDown";
import { LeaderboardIsEmpty } from "./components/leaderboards/LeaderboardIsEmpty";
import { TextWithLines } from "./components/TextWithLines/TextWithLines";
import { LeaderboardTopThree } from "./components/leaderboards/LeaderboardTopThree";
import { TimerComp } from "./components/TimerComp";
import { LeaderboardFull } from "./components/leaderboards/LeaderboardFull";
import { CS2TIMEDEndscreen } from "./CS2-TIMED_endScreen";

export const CS2TIMEDLeaderboard: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const hasActiveChallengeData =
    clientData.hasActiveChallenge && clientData.leaderboard.data;

  const { time, startTimer, timeObj, isTimeOver, clearTimer } = useTimer(
    clientData.challenge.data?.endDate || undefined
  );

  const leaderboardLength = clientData.leaderboard.length as number;
  const [endScreen, setEndScreen] = useState<boolean>(false);

  const [hasRanAlternate, setHasRanAlternate] = useState<boolean>(false);
  const [alternateState, setAlternateState] = useState<number>(0);
  let intervalId;
  let index = 0;

  const startInterval = () => {
    intervalId = setInterval(() => {
      setHasRanAlternate(true);
      if (index === 1) {
        index = 0;

        return setAlternateState(0);
      } else if (index === 0) {
        index = 1;
        return setAlternateState(1);
      }
    }, 15000);
  };
  useEffect(() => {
    LeaderboardToRender();
    if (!hasRanAlternate) {
      startInterval();
    }
  }, []);

  useEffect(() => {
    let hasRan;
    if (!hasRan && !clientData.nextChallenges.loading && isTimeOver) {
      hasRan = true;

      if (
        isTimeOver &&
        (!clientData.nextChallenges.data ||
          clientData.nextChallenges.data?.length === 0)
      ) {
        if (
          !clientData?.challenge?.data ||
          clientData.challenge.data.status === "finished"
        ) {
          setEndScreen(true);
          clearTimer();
        }
      }
    }
  }, [time, isTimeOver]);

  if (endScreen) {
    clearInterval(intervalId);
    index = 10;
    return <CS2TIMEDEndscreen clientData={clientData} />;
  }

  const LeaderboardToRender = () => {
    // return <LeaderboardTopThree clientData={clientData} />;

    if (!leaderboardLength || leaderboardLength === 0) {
      return <LeaderboardIsEmpty />;
    }

    if (leaderboardLength <= 3) {
      return <LeaderboardTopThree clientData={clientData} />;
    }

    return <LeaderboardFull clientData={clientData} />;
  };

  if (alternateState === 1) {
    clearInterval(intervalId);
    return <CS2TIMEDEndscreen clientData={clientData} />;
  }

  const Logo = brand.brandLogo;

  return (
    <>
      {/* IF ACTIVE CHALLENGE*/}
      {hasActiveChallengeData && (
        <>
          <div className={`overview ${leaderboardLength === 0 ? "empty" : ""}`}>
            <div className="waiting-screen__heading">
              <div className="brand-logo">
                <Logo />
              </div>
            </div>
            <TextWithLines text="cs2 time trial" />
            <div className="overview__prize">
              <h4>
                set the fastest time - win a <span>JBL QUANTUM 910</span> gaming
                headset
              </h4>
            </div>
            <div
              className={`overview__content ${
                leaderboardLength === 0 ? "empty" : ""
              } ${leaderboardLength > 3 ? "full" : ""}`}
            >
              <section className="leaderboard">{LeaderboardToRender()}</section>
            </div>
            <div>
              <TimerComp time={time} />
            </div>
          </div>
        </>
      )}
    </>
  );
};
