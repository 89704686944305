import { FC } from "react";
import { ClientProps } from "../../hooks/useClientData";
import "./styles/index.scss";
import BaseUserAvatar from "../../components/reusable/BaseUserAvatar";
export const FORTNITEEndScreen: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const winners = clientData.winners.data;
  const winningDays = ["Wednesday", "Thursday", "Friday"];

  return (
    <div className="endScreen">
      {winners && (
        <>
          <h1>Thank you and congratulations to our winners!</h1>
          <div>
            <ul className="endScreen__days">
              {winners && (
                <li className="winners">
                  {winningDays.map((day) => {
                    if (
                      winners.filter((obj) => obj.winningDay === day).length !==
                      0
                    ) {
                      return (
                        <section>
                          <div className="winners__header">
                            <h2>{day}</h2>
                          </div>
                          <ul className="winners__list">
                            {winners.map((winner) => {
                              if (winner.winningDay === day) {
                                return (
                                  <li>
                                    <BaseUserAvatar
                                      userName={winner.userName}
                                      logoUrl={winner.userProfileLogoUrl}
                                    />
                                    <p>{winner.userName}</p>
                                    <span>{winner.points}</span>
                                  </li>
                                );
                              }
                            })}
                          </ul>
                        </section>
                      );
                    }
                  })}
                </li>
              )}
            </ul>
          </div>
        </>
      )}
      {!winners && <h1>Thank you for participating!</h1>}
    </div>
  );
};
