import { useState, useEffect } from "react";
import firebase from "firebase/app"; // Assuming firebase is imported properly
import { brand } from "../brandVariables/getBandVariables";
import { fullDays } from "../consts/weekDays";

export const useWinners = (client: string) => {
  const [winnersData, setWinnersData] = useState<any[] | null>(null);
  const [loadingWinners, setLoadingWinners] = useState(true);
  const [lastClient, setLastClient] = useState<string>();
  const getWinners = async () => {
    setLastClient(client);

    try {
      setLoadingWinners(true);

      const winnersQuery = firebase
        .firestore()
        .collection("event-winners")
        .doc(brand.eventCode)
        .collection("winners");

      winnersQuery.onSnapshot((snapshot) => {
        const winnersTempArr = [] as any;

        snapshot.forEach((doc) => {
          winnersTempArr.push({
            ...doc.data(),
            winningDay:
              fullDays[
                new Date(doc.data().winningDate.seconds * 1000)
                  .toDateString()
                  .split(" ")[0] as string
              ],
          });
        });

        const sortedWinners = winnersTempArr.sort(
          (a, b) => b.winningDate.seconds - a.winningDate.seconds
        );

        setWinnersData(sortedWinners);
        setLoadingWinners(false);
      });
    } catch (error) {
      console.error("Error:", error);
      setLoadingWinners(false);
    } finally {
      setLoadingWinners(false);
    }
  };

  useEffect(() => {
    if (client !== undefined) {
      if (winnersData && client !== lastClient) {
        getWinners();
      }
    }
  }, []);

  useEffect(() => {
    if (winnersData) {
      return;
    }
    getWinners();
  }, []);

  const fetchNewWinners = () => {
    getWinners();
  };

  return { fetchNewWinners, winnersData, loadingWinners };
};
