import { FC } from "react";
import { ClientProps } from "../../hooks/useClientData";
import { useTimer } from "../../hooks/useCountDown";
import "./styles/index.scss";
import { brand } from "../../brandVariables/getBandVariables";
export const FALLGUYSWaitingScreen: FC<{
  clientData: ClientProps;
}> = ({ clientData }): JSX.Element => {
  const { time, startTimer, timeObj } = useTimer(
    clientData.nextChallenges?.data?.[0]?.startDate || undefined
  );
  return (
    <div className="waiting-screen">
      <section>
        <div className="waiting-screen__heading">
          <div className="brand-logo">
            <img src={brand.brandLogo} alt="" />
          </div>
        </div>
        <div className="waiting-screen__countdown">
          <h2>New challenge in</h2>
          <section className="countdown">
            <div className="countdown__hours countdown__block">
              <div className="block">
                <p>{timeObj?.hours[0] || "-"}</p>
                <div />
                <div />
              </div>
              <div className="block">
                <p>{timeObj?.hours[1] || "-"}</p>
                <div />
                <div />
              </div>
            </div>
            <span className="countdown__dots" />
            <div className="countdown__mins countdown__block">
              <div className="block">
                <p>{timeObj?.minutes[0] || "-"}</p>
                <div />
                <div />
              </div>{" "}
              <div className="block">
                <p> {timeObj?.minutes[1] || "-"}</p>
                <div />
                <div />
              </div>
            </div>
            <span className="countdown__dots" />
            <div className="countdown__seconds countdown__block">
              <div className="block">
                <p>{timeObj?.seconds[0] || "-"}</p>
                <div />
                <div />
              </div>{" "}
              <div className="block">
                <p>{timeObj?.seconds[1] || "-"}</p>
                <div />
                <div />
              </div>
            </div>
          </section>
          {brand.prize && (
            <h3>
              win a <span>{brand.prize?.nameOf}</span> {brand.prize?.typeOfItem}{" "}
            </h3>
          )}
        </div>
      </section>
    </div>
  );
};
