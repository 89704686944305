import { brand } from "@/brandVariables/getBandVariables";
import { ClientProps } from "@/hooks/useClientData";
import { FC } from "react";
import { TextWithLines } from "./components/TextWithLines/TextWithLines";
import { UserCard } from "./components/userCard/UserCard";

export const CS2TIMEDEndscreen: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const Logo = brand.brandLogo;
  const winners = clientData.winners.data;
  const winningDays = [
    // "Monday",
    // "Tuesday",
    // "Wednesday",
    // "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const hasActiveChallengeData =
    clientData.hasActiveChallenge && clientData.leaderboard.data;

  return (
    <div className="endscreen">
      <div className="waiting-screen__heading">
        <div className="brand-logo">
          <Logo />
        </div>
      </div>

      {!hasActiveChallengeData && (
        <h1>
          {winners ? (
            <>
              <TextWithLines text="Thank you" />
              <div className="overview__prize">
                <h4>
                  and congratulations to our <span>winners!</span>
                </h4>
              </div>
            </>
          ) : (
            <>Thank you to all participants</>
          )}
        </h1>
      )}

      {hasActiveChallengeData && (
        <h1>
          {winners ? (
            <>
              <TextWithLines text="Dreamhack winter 2024 winners" />
              <div className="overview__prize">
                <h4>
                  <span>overview</span>
                </h4>
              </div>
            </>
          ) : (
            <>Thank you to all participants</>
          )}
        </h1>
      )}

      {winners && (
        <div className="winners">
          {winningDays.map((day) => {
            if (winners.filter((obj) => obj.winningDay === day).length !== 0) {
              return (
                <section>
                  <div className="winners__header">
                    <h2>{day}</h2>
                  </div>
                  <div className="winners__day">
                    <ul className="userCards">
                      {winners.map((winner) => {
                        if (winner.winningDay === day) {
                          return (
                            <UserCard
                              user={{
                                profile: {
                                  userName: winner.userName,
                                  profileLogoUrl: winner.userProfileLogoUrl,
                                },
                                userChallengeData: {
                                  points: winner.points,
                                },
                              }}
                              place={undefined}
                            />
                          );
                        }
                      })}
                    </ul>
                  </div>
                </section>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};
