import { FC, useEffect, useState } from "react";
import { ClientProps } from "../../hooks/useClientData";
import { brand } from "../../brandVariables/getBandVariables";
import "./styles/index.scss";
import { useTimer } from "../../hooks/useCountDown";
import { EndScreen } from "../../pages/endScreen";
import { LeaderboardIsEmpty } from "./components/leaderboardStates/LeaderboardIsEmpty";
import { TimerComp } from "./components/timerComp";
import { LeaderboardTopThree } from "./components/leaderboardStates/LeaderboardTopThree";
import { Fortnite_leaderboard } from "./components/Fortnite_leaderBoard";
import { WinnerCard } from "./components/WinnerCard";
import { database_1 } from "../../BE/firebaseConfig";
import { createOverlay } from "../../helperFunctions/showOverlayReplay";

export const FORTNITELeaderboard: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const hasActiveChallenge =
    clientData.leaderboard.data && !clientData.leaderboard.loading;

  const hasActiveChallengeData =
    clientData.hasActiveChallenge && clientData.leaderboard.data;

  const { time, startTimer, timeObj, isTimeOver, clearTimer } = useTimer(
    clientData.challenge.data?.endDate || undefined
  );
  const winner = clientData.winners.data?.[0];
  const leaderboardLength = clientData.leaderboard?.length as number;
  const [endScreen, setEndScreen] = useState<boolean>(false);
  const queTimeOut = 2000;
  const game = "fortnite";

  type Props = {
    key: string;
    data: {
      createdAt: number;
      duration: number;
      game: string;
      isViewed: boolean;
      uid: string;
      url: string;
    };
  };

  const [quedVideos, setQuedVideos] = useState<Props[]>([]);
  // const [nextVideoInQue, setNextVideoToPlay] = useState<Props | null>(null);
  const nextVideoInQue = quedVideos[0] || null;

  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  let localCopy: Props[] = [];

  useEffect(() => {
    if (!nextVideoInQue || isVideoPlaying || !quedVideos[0]) {
      return;
    }

    let data = quedVideos[0];

    setIsVideoPlaying(true);
    handleVideoQueing(data);
  }, [quedVideos, nextVideoInQue]);

  const deleteVideoFromQue = (video) => {
    const deepArr = [...quedVideos];
    const test = deepArr.shift();
    setQuedVideos(deepArr);
  };

  const handleVideoQueing = async (video: Props) => {
    await createOverlay(nextVideoInQue.data.duration).then(() => {
      database_1
        .ref("highlights")
        .child(game)
        .child(video.key)
        .update({ isViewed: true });
    });
  };

  const databaseRef = database_1
    .ref("highlights")
    .child(game)
    .orderByChild("isViewed")
    .equalTo(null);

  useEffect(() => {
    const handleNewVideos = (snapshot) => {
      let newVideosForQue: Props[] = [];
      if (snapshot.val()) {
        Object.keys(snapshot.val())?.forEach((key, index) => {
          let data = snapshot.val()[key];

          if (!localCopy.find((obj) => obj.key === key)) {
            localCopy.push({
              key,
              data,
            });

            return newVideosForQue.push({
              key,
              data,
            });
          }
        });
      }
      setQuedVideos((prev) => [...prev, ...newVideosForQue]);
    };

    databaseRef.on("value", handleNewVideos);

    return () => {
      databaseRef.off("value", handleNewVideos);
    };
  }, []);

  useEffect(() => {
    let hasRan;
    if (!hasRan && !clientData.nextChallenges.loading && isTimeOver) {
      hasRan = true;

      if (
        (isTimeOver && !clientData.nextChallenges.data) ||
        clientData.nextChallenges.data?.length === 0
      ) {
        clearTimer();
        setEndScreen(true);
      }
    }
  }, [time, isTimeOver]);

  if (endScreen) {
    return <EndScreen clientData={clientData} />;
  }

  const LeaderboardToRender = () => {
    if (!leaderboardLength || leaderboardLength === 0) {
      return <LeaderboardIsEmpty clientData={clientData} />;
    }

    if (brand.gameStyle === "fortnite" && leaderboardLength <= 3) {
      return <LeaderboardTopThree clientData={clientData} />;
    }

    return <Fortnite_leaderboard clientData={clientData} />;
  };

  return (
    <>
      {/* IF ACTIVE CHALLENGE*/}
      {hasActiveChallengeData && (
        <>
          <div className={`overview ${leaderboardLength === 0 ? "empty" : ""}`}>
            <div
              className={`overview__content ${
                leaderboardLength === 0 ? "empty" : ""
              }`}
            >
              <div className="overview__winners">
                {/* <span>{JSON.stringify(quedVideos)}</span> */}
                {winner && (
                  <>
                    <p>Previous winner</p>
                    <ul>
                      <li>
                        <WinnerCard
                          clientData={clientData}
                          winnerToUser={winner}
                          hasBanner={false}
                        />
                      </li>
                    </ul>
                  </>
                )}
              </div>
              <section className="leaderboard">{LeaderboardToRender()}</section>
            </div>

            {leaderboardLength <= 3 && <TimerComp time={time} />}
          </div>
        </>
      )}
      <div className="replay">
        <video
          onEnded={() => {
            const replayDoc = document.querySelector(".replay");
            if (!replayDoc) {
              return;
            }

            replayDoc.classList.remove("open");
            replayDoc.classList.add("closed");
            setTimeout(() => {
              deleteVideoFromQue(nextVideoInQue);
              setIsVideoPlaying(false);
              return;
            }, 1000);
          }}
          src={nextVideoInQue?.data?.url}
          muted
          preload="auto"
          autoPlay
          controls={false}
        />
      </div>
    </>
  );
};
