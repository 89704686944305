import { ClientProps } from "@/hooks/useClientData";
import { FC } from "react";
import { TopThreeCard } from "./../topThreeCard/TopThreeCard";

export const LeaderboardTopThree: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const topThreeUsers = clientData?.leaderboard?.data?.slice(0, 3);

  return (
    <div className="topThree">
      {topThreeUsers?.length !== 0 &&
        topThreeUsers.map((user, index) => {
          return <TopThreeCard place={index} user={user} />;
        })}
    </div>
  );
};
