import React, { useState, useEffect } from "react";

const BrowserInfo: React.FC = () => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    ppi: 0,
    dpr: 0,
  });

  useEffect(() => {
    const updateDimensions = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const ppi =
        Math.sqrt(
          Math.pow(window.screen.width, 2) + Math.pow(window.screen.height, 2)
        ) /
        Math.sqrt(
          Math.pow(window.screen.width / window.devicePixelRatio, 2) +
            Math.pow(window.screen.height / window.devicePixelRatio, 2)
        );
      const dpr = window.devicePixelRatio;
      setDimensions({ width, height, ppi, dpr });
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="browser-info">
      <h4>Screen resolution info</h4>
      <p>Width: {dimensions.width}px</p>
      <p>Height: {dimensions.height}px</p>
      <p>PPI: {dimensions.ppi.toFixed(2)}</p>
      <p>Device Pixel Ratio: {dimensions.dpr}</p>
    </div>
  );
};

export default BrowserInfo;
