import { formattedTime } from "@/helperFunctions/formatMsToTime";

export const ScoreCard = ({ place, score }: { place: number; score: any }) => {
  const formattedScore = formattedTime(score);

  return (
    <section className={`scoreCard scoreCard-${place}`}>
      <p>{formattedScore !== "NAN:NAN:NAN" ? formattedScore : score}</p>
    </section>
  );
};
