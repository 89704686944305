import { FC } from "react";
import { ReactComponent as QrBg } from "./assets/qr_bg.svg";
import QRCode from "react-qr-code";

export const FALLGUYS_qrCode: FC<{}> = ({}) => {
  return (
    <div className="qr-code">
      <section>
        <h1>scan this qr code</h1>
        <h2>To participate in Fall Guys challenge</h2>
        <div className="qr-code__code">
          <div className="code">
            <div className="code__scanable">
              <QRCode value="ello" />
            </div>
            <div className="code__background">
              <QrBg />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
