import { useState, useEffect } from "react";

export function formattedTime(milliseconds) {
  // Function to format milliseconds into time string

  const date = new Date(milliseconds);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();
  const mlSeconds = date.getUTCMilliseconds();

  const formattedTime = [
    minutes.toString().padStart(2, "0"),
    seconds.toString().padStart(2, "0"),
    mlSeconds.toString().padStart(3, "0"),
  ].join(":");

  return formattedTime;
}
