import { FC } from "react";
import { ClientProps } from "../../../../hooks/useClientData";

export const LeaderboardHasTwoScores: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const winners = clientData.leaderboard.data?.slice(0, 1);

  return (
    <>
      <h1>LEADERBOARD 2</h1>
    </>
  );
};
