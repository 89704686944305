import brandLogo from "../../components/FALLGUYS/assets/Rectangle.png";
export const FALLGUYS_brandVariables = {
  client: "", // For picking wich leaderboard to show
  layoutKeyValue: "FORTNITE",
  brand: "fallguys", // For scss
  gameStyle: "fallguys", // For picking wich leaderboard to show
  eventCode: "fallguys_test_spring_2024", // Needed for fetching correct challenge
  // eventCode: "logitech_gamebox_spring_2024", // Needed for fetching correct challenge
  challengeStatus: "ongoing",
  challengeGameType: "fallguys",
  brandLogo: brandLogo,
  leaderboardLength: 7,
  prize: {
    nameOf: " branded Lenovo Legion backpack!", // will be marked in color
    typeOfItem: "", // will be added after nameOf
  },
};
