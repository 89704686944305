import { ReactComponent as BrandLogo } from "../../assets/elgiganten_logo.svg";
export const ELGIGANTEN_brandVariables = {
  client: "ELGIGANTEN", // For picking wich leaderboard to show
  layoutKeyValue: "ELGIGANTEN",
  brand: "elgiganten", // For scss
  gameStyle: "racing", // For picking wich leaderboard to show
  eventCode: "elgiganten_dreamhack_winter_2024", // Needed for fetching correct challenge
  challengeStatus: "ongoing",
  challengeGameType: "f12024",
  brandLogo: BrandLogo,
  leaderboardLength: 7,
  prize: {
    nameOf: "Dreamhack coins", // will be marked in color
    typeOfItem: "wireless gaming mouse", // will be added after nameOf
  },
};
