import { FC } from "react";
import { ClientProps } from "../../../../hooks/useClientData";
// import { mockLeaderboardData } from "./mockLeaderboardData";
import BaseUserAvatar from "../../../../components/reusable/BaseUserAvatar";

export const LeaderboardTopThree: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const winners = clientData.leaderboard.data?.slice(0, 3);

  return (
    <div className="leaderboard__topThree">
      <div className="topThree__heading">
        <h1>Beat the top score</h1>
        <p>Fortnite challenge</p>
      </div>
      <ul className="topThree">
        {winners?.map((winner, index) => {
          return (
            <li>
              <div className="topThree__rank">
                <section>
                  <p>#{index + 1}</p>
                </section>
              </div>
              <div className="topThree__info">
                <section>
                  <BaseUserAvatar
                    userName={winner.profile.userName}
                    logoUrl={winner.profile.profileLogoUrl}
                  />
                  <p className="topThree__name">{winner.profile.userName}</p>
                </section>
              </div>
              <div className="topThree__score">
                <section>
                  <p>{winner.userChallengeData.points || "-"}</p>
                </section>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
