import { FC, useEffect, useState } from "react";
import { ClientProps } from "../../hooks/useClientData";
import { brand } from "../../brandVariables/getBandVariables";
import "./styles/index.scss";
import { useTimer } from "../../hooks/useCountDown";
import { EndScreen } from "../../pages/endScreen";
import { LeaderboardIsEmpty } from "./components/leaderboardStates/LeaderboardIsEmpty";
import { Fallguys_leaderboard } from "./components/Fallguys_leaderboard";
import { LeaderboardTopThree } from "./components/leaderboardStates/LeaderboardTopThree";

export const FALLGUYSLeaderboard: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const hasActiveChallenge =
    clientData.leaderboard.data && !clientData.leaderboard.loading;

  const hasActiveChallengeData =
    clientData.hasActiveChallenge && clientData.leaderboard.data;

  const { time, startTimer, timeObj, isTimeOver, clearTimer } = useTimer(
    clientData.challenge.data?.endDate || undefined
  );

  const leaderboardLength = clientData.leaderboard?.length as number;
  const [endScreen, setEndScreen] = useState<boolean>(false);

  useEffect(() => {
    let hasRan;
    if (!hasRan && !clientData.nextChallenges.loading && isTimeOver) {
      hasRan = true;

      if (
        (isTimeOver && !clientData.nextChallenges.data) ||
        clientData.nextChallenges.data?.length === 0
      ) {
        if (
          !clientData?.challenge?.data ||
          clientData.challenge.data.status === "finished"
        ) {
          setEndScreen(true);
          clearTimer();
        }
      }
    }
  }, [time, isTimeOver]);

  if (endScreen) {
    return <EndScreen clientData={clientData} />;
  }

  const LeaderboardToRender = () => {
    // return <LeaderboardTopThree clientData={clientData} />;

    if (!leaderboardLength || leaderboardLength === 0) {
      return <LeaderboardIsEmpty clientData={clientData} />;
    }

    if (brand.gameStyle === "fallguys" && leaderboardLength <= 3) {
      return <LeaderboardTopThree clientData={clientData} />;
    }

    return <Fallguys_leaderboard clientData={clientData} />;
  };

  return (
    <>
      {/* IF ACTIVE CHALLENGE*/}
      {hasActiveChallengeData && (
        <>
          <div className={`overview ${leaderboardLength === 0 ? "empty" : ""}`}>
            <div
              className={`overview__content ${
                leaderboardLength === 0 ? "empty" : ""
              }`}
            >
              <section className="leaderboard">{LeaderboardToRender()}</section>
            </div>

            {/* {leaderboardLength <= 3 && <TimerComp time={time} />} */}
          </div>
        </>
      )}
    </>
  );
};
