import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { brand } from "./brandVariables/getBandVariables";
import { auth } from "./BE/firebaseConfig";
import firebase from "firebase/app";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (!auth.currentUser?.uid) {
  firebase
    .auth()
    .signInWithEmailAndPassword(
      "leaderboardauthuser@gmail.com",
      "leaderboardauthuser"
    )
    .then(() => {
      firebase.auth().currentUser?.reload();
    });
}
// Check if client exists
if (!brand?.client) {
  root.render(<>Client not found</>);
} else {
  root.render(<App />);
}
