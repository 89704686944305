import { FC } from "react";
import { ClientProps } from "../../../hooks/useClientData";
import { leaderboardUsers } from "../../../types/leaderboardUsersResponse";
import BaseUserAvatar from "../../../components/reusable/BaseUserAvatar";
import { useTimer } from "../../../hooks/useCountDown";
import { ReactComponent as RankRest } from "../assets/Rank_rest.svg";
import { ReactComponent as RankGold } from "../assets/Rank_gold.svg";
import { brand } from "../../../brandVariables/getBandVariables";
import { TimerComp } from "./TimerComp";
import { BrandLogo } from "./LogoComp";
import { WinnerCard } from "./WinnerCard";

export const Fallguys_leaderboard: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const users = clientData.leaderboard.data?.slice(0, 6) as leaderboardUsers[];
  const length = users?.length || (0 as number);

  const { time, startTimer, timeObj, isTimeOver, clearTimer } = useTimer(
    clientData.challenge.data?.endDate || undefined
  );

  return (
    <div className="leaderboard__list">
      <BrandLogo />
      <div className="list">
        <div className="leaderboard__heading">
          <h1>Leaderboard</h1>
        </div>
        <div>
          <ul>
            {users.map((user, index) => {
              if (index === 0) {
                return (
                  <li className="list__first-place">
                    <figure>
                      <RankGold />
                    </figure>
                    <div className="topThree__rank">
                      <p>#{index + 1}</p>
                    </div>
                    <div className="topThree__info">
                      <BaseUserAvatar
                        userName={user.profile.userName}
                        logoUrl={user.profile.profileLogoUrl}
                      />
                      <p className="topThree__name">{user.profile.userName}</p>
                    </div>
                    <div className="topThree__score">
                      <p>{user.userChallengeData?.points || "-"}</p>
                    </div>
                  </li>
                );
              }
              return (
                <li className="list__rest">
                  <figure>
                    <RankRest />
                  </figure>
                  <div className="topThree__rank">
                    <p>#{index + 1}</p>
                  </div>
                  <div className="topThree__info">
                    <BaseUserAvatar
                      userName={user.profile.userName}
                      logoUrl={user.profile.profileLogoUrl}
                    />
                    <p className="topThree__name">{user.profile.userName}</p>
                  </div>
                  <div className="topThree__score">
                    <p>{user.userChallengeData?.points || "-"}</p>
                  </div>
                </li>
              );
            })}

            <div></div>
          </ul>
        </div>
      </div>
      <div>
        <TimerComp time={time} />
      </div>
      <div className="winner__label">
        <WinnerCard winner={clientData.winners?.data?.[0] || null} hasLabel />
      </div>
    </div>
  );
};
