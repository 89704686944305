import "./textWithLines.scss";

export const TextWithLines = ({ text }: { text: string }) => {
  return (
    <div className="textWithLine">
      <span className="line line-1" />
      {/* <span className="line line-2" />
      <span className="line line-3" /> */}
      <h2>{text}</h2>
      <span className="line line-2" />
      {/* <span className="line line-5" /> */}
    </div>
  );
};
