import brandLogo from "../../components/RACING/assets/Logitech logo.svg";
export const FORTNITE_brandVariables = {
  client: "", // For picking wich leaderboard to show
  layoutKeyValue: "FORTNITE",
  brand: "Fortnite", // For scss
  gameStyle: "fortnite", // For picking wich leaderboard to show
  eventCode: "fortnite_test_spring_2024", // Needed for fetching correct challenge
  // eventCode: "logitech_gamebox_spring_2024", // Needed for fetching correct challenge
  challengeStatus: "ongoing",
  challengeGameType: "fortnite",
  brandLogo: brandLogo,
  leaderboardLength: 7,
  prize: {
    nameOf: "logitech lightspeed ", // will be marked in color
    typeOfItem: "wireless gaming mouse", // will be added after nameOf
  },
};
