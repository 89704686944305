export const createOverlay = async (durationInSeconds) => {
  const replayDoc = document.querySelector(".replay");

  if (!replayDoc) {
    return;
  }

  replayDoc.classList.remove("closed");
  replayDoc.classList.add("open");
};
