import firebase from "firebase/app";

export const setCurrentChallengeToOnGoing = (slugToUpdate) => {
  console.log("running");

  firebase
    .firestore()
    .collection("challenges")
    .doc(slugToUpdate)
    .update({
      status: "ongoing",
    })
    .then((res) => {
      console.log(res, "update");
    })
    .catch((err) => {
      console.log(err);
    });
};
