import { FC } from "react";
import { ReactComponent as QrBg } from "./assets/qr_bg.svg";
import QRCode from "react-qr-code";
import { brand } from "@/brandVariables/getBandVariables";
import { ClientProps } from "@/hooks/useClientData";
import { useTimer } from "@/hooks/useCountDown";

export const RACING_qrCode: FC<{
  clientData: ClientProps;
}> = ({ clientData }): JSX.Element => {
  const nextChallenge = clientData?.nextChallenges?.data?.[0];
  const currentChallenge = clientData?.challenge?.data;

  const linkToChallenge = currentChallenge?.slug || nextChallenge?.slug || "";
  const challengeType = currentChallenge?.game || nextChallenge?.game || "";
  const hasChallenge = currentChallenge?.slug || nextChallenge?.slug;
  const isElgiganten = brand.client === "ELGIGANTEN" ? "text-gradient" : "";
  const { time, startTimer, timeObj, isTimeOver, clearTimer } = useTimer(
    nextChallenge?.startDate || undefined
  );

  const QRLinks = {
    elgiganten: "https://elgiganten.iplay.dk/",
    logitech: "https://logitech.iplay.dk/",
    jbl: "https://jbl.iplay.dk/",
  };

  const renderHeadline = () => {
    if (currentChallenge?.slug) {
      return `scan to join the ${challengeType} challenge`;
    }

    if (!currentChallenge?.slug && nextChallenge) {
      return `Next challenge starting in ${time}`;
    }

    if (!hasChallenge) {
      return "No challenge active, please come back later";
    }
  };

  return (
    <div className="qr-code">
      <section>
        <h1>scan this qr code</h1>
        <h2>
          Drive the fastest lap time and win <span>{brand.prize.nameOf}</span>{" "}
        </h2>
        <div className="qr-code__code">
          <p className={`${isElgiganten}`}>{renderHeadline()}</p>
          <div className="code">
            <div className="code__scanable">
              <QRCode
                value={
                  hasChallenge
                    ? `${QRLinks[brand.brand]}challenges/${linkToChallenge}`
                    : "No challenge active"
                }
              />
            </div>
            <div className="code__background">
              <QrBg />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
