import BaseUserAvatar from "@/components/reusable/BaseUserAvatar";
import { ScoreCard } from "../scoreCard/ScoreCard";

export const UserCard = ({ place, user }: { place: number; user: any }) => {
  return (
    <li className={`userCards__item ${place ? "" : "no-score"}`}>
      {place && <p className="userCards__score">{place}</p>}
      <div className="userCards__user">
        {" "}
        <BaseUserAvatar
          userName={user.profile.userName}
          logoUrl={user.profile.profileLogoUrl}
        />
        <p>{user.profile.userName}</p>
      </div>
      <div className="userCards__points">
        <ScoreCard place={place} score={user.userChallengeData.points} />
      </div>
    </li>
  );
};
