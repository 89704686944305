import { cacheDatabase } from "../BE/firebaseConfig";

export async function getAuthorProfileBasedOnUid(
  uid: string,
  platform: "WOG" | "LEGION" | string
) {
  const userNameDoc = await cacheDatabase
    .ref("/userNames")
    .child(platform)
    .orderByChild("uid")
    .equalTo(uid)
    .get();

  const users: Response[] = [];
  userNameDoc.forEach((val) => {
    const user = val.val() as Response;
    users.push(user);
  });
  return users[0];
}
