import { FC } from "react";
import { ClientProps } from "../../../../hooks/useClientData";
import BaseUserAvatar from "../../../../components/reusable/BaseUserAvatar";
import { brand } from "../../../../brandVariables/getBandVariables";
import { ReactComponent as RankGold } from "../../assets/Rank_gold.svg";
import { ReactComponent as RankSilver } from "../../assets/Rank_silver.svg";
import { ReactComponent as RankBronze } from "../../assets/Rank_bronze.svg";
import { TimerComp } from "../TimerComp";
import { useTimer } from "../../../../hooks/useCountDown";
import { BrandLogo } from "../LogoComp";
export const LeaderboardTopThree: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const winners = clientData.leaderboard.data?.slice(0, 3);
  const { time, startTimer, timeObj, isTimeOver, clearTimer } = useTimer(
    clientData.challenge.data?.endDate || undefined
  );
  const ranks = {
    0: <RankGold />,
    1: <RankSilver />,
    2: <RankBronze />,
  };
  return (
    <div className="leaderboard__topThree">
      <BrandLogo />
      <div className="topThree__heading">
        <h1>Beat the best score</h1>
        <h2>
          Win a {brand.prize?.nameOf}
          {brand.prize?.typeOfItem}{" "}
        </h2>
      </div>
      <ul className="topThree">
        {winners?.map((winner, index) => {
          if (index === 0 || index === 2) {
            return (
              <li>
                <figure>{ranks[index]}</figure>
                <div className="topThree__rank">
                  <p>#{index + 1}</p>
                </div>
                <div className="topThree__info">
                  <BaseUserAvatar
                    userName={winner.profile.userName}
                    logoUrl={winner.profile.profileLogoUrl}
                  />
                  <p className="topThree__name">{winner.profile.userName}</p>
                </div>
                <div className="topThree__score">
                  <p>{winner.userChallengeData.points || "-"}</p>
                </div>
              </li>
            );
          }
          if (index === 1) {
            return (
              <li>
                <figure>{ranks[index]}</figure>
                <div className="topThree__info">
                  <BaseUserAvatar
                    userName={winner.profile.userName}
                    logoUrl={winner.profile.profileLogoUrl}
                  />
                  <p className="topThree__name">{winner.profile.userName}</p>
                </div>
                <div className="topThree__score">
                  <p>{winner.userChallengeData.points || "-"}</p>
                </div>
                <div className="topThree__rank">
                  <p>#{index + 1}</p>
                </div>
              </li>
            );
          }
        })}
      </ul>
      <div>
        <TimerComp time={time} />
      </div>
    </div>
  );
};
