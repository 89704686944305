import "./style/applications.scss";
import { brand } from "./brandVariables/getBandVariables";
import { useClientData } from "./hooks/useClientData";
import {
  FALLGUYSWaitingScreen,
  EndScreen,
  FORTNITEEndScreen,
  FORTNITELeaderboard,
  FORTNITEWaitingScreen,
  JBLLeaderboard,
  RACINGLeaderboard,
  RACINGWaitingScreen,
  WaitingScreen,
  FALLGUYSLeaderboard,
  FALLGUYSEndScreen,
  FALLGUYS_qrCode,
  RACING_qrCode,
} from "./components";
import { CS2TIMEDWaitingScreen } from "./components/CS2-TIMED/CS2-TIMED_waitingScreen";
import { CS2TIMEDLeaderboard } from "./components/CS2-TIMED/CS2-TIMED_leaderboard";

import backgroundVideo from "./assets/jbl bg.webm";
import { CS2TIMEDEndscreen } from "./components/CS2-TIMED/CS2-TIMED_endScreen";
import { RACINGEndScreen } from "./components/RACING/RACING_endScreen";
import BrowserInfo from "./hooks/BrowserInfo/BrowserInfo";

function App() {
  const { clientData } = useClientData(brand.client);

  console.log("ClientData:", clientData);

  const uisToRender = {
    // jbl racing: TODO
    // JBL: {
    //   leaderboard: <JBLLeaderboard clientData={clientData} />,
    //   endScreen: <EndScreen clientData={clientData} />,
    //   waitingScreen: <RACINGWaitingScreen clientData={clientData} />,
    // },
    LOGITECH: {
      leaderboard: <RACINGLeaderboard clientData={clientData} />,
      endScreen: <RACINGEndScreen clientData={clientData} />,
      waitingScreen: <RACINGWaitingScreen clientData={clientData} />,
    },
    ELGIGANTEN: {
      leaderboard: <RACINGLeaderboard clientData={clientData} />,
      endScreen: <RACINGEndScreen clientData={clientData} />,
      waitingScreen: <RACINGWaitingScreen clientData={clientData} />,
    },

    FORTNITE: {
      leaderboard: <FORTNITELeaderboard clientData={clientData} />,
      endScreen: <FORTNITEEndScreen clientData={clientData} />,
      waitingScreen: <FORTNITEWaitingScreen clientData={clientData} />,
    },
    FALLGUYS: {
      leaderboard: <FALLGUYSLeaderboard clientData={clientData} />,
      endScreen: <FALLGUYSEndScreen clientData={clientData} />,
      waitingScreen: <FALLGUYSWaitingScreen clientData={clientData} />,
    },

    JBLCS2TIMED: {
      leaderboard: <CS2TIMEDLeaderboard clientData={clientData} />,
      endScreen: <CS2TIMEDEndscreen clientData={clientData} />,
      waitingScreen: <CS2TIMEDWaitingScreen clientData={clientData} />,
    },
  } as Record<string, Record<string, JSX.Element | undefined>>;

  // SHOW PAGE LOADED
  const showPageLoader =
    clientData.leaderboard.loading || clientData.challenge.loading;
  /* ----------------------------------------------------*/

  // IF NO ACTIVE CHALLENGE & HAS UPCOMING CHALLENGE
  const noActiveChallengeButHasUpcoming =
    !clientData.hasActiveChallenge &&
    clientData.nextChallenges.data &&
    clientData.nextChallenges?.data?.length !== 0 &&
    !clientData.challenge.loading;
  /* ----------------------------------------------------*/

  // IF NO ACTIVE CHALLENGE & HAS NO UPCOMING CHALLENGE
  const noActiveChallengeAndNoUpcomming =
    !clientData.nextChallenges.data &&
    !clientData.challenge.loading &&
    !clientData.challenge.data;
  /* ----------------------------------------------------*/

  document.title = `${brand.brand} - leaderboard`;

  const getEllToRender = () => {
    if (showPageLoader) {
      return <span className="loading-spinner" />;
    }

    if (brand.gameStyle === "fallguys" && window.location.pathname === "/qr") {
      return <FALLGUYS_qrCode />;
    }

    if (brand.gameStyle === "racing" && window.location.pathname === "/qr") {
      return <RACING_qrCode clientData={clientData} />;
    }

    if (noActiveChallengeButHasUpcoming) {
      return uisToRender[brand.layoutKeyValue]?.waitingScreen;
    }
    if (noActiveChallengeAndNoUpcomming) {
      return uisToRender[brand.layoutKeyValue]?.endScreen;
    }

    return uisToRender[brand.layoutKeyValue]?.leaderboard;
  };

  if (window.location.pathname.includes("test")) {
    return <BrowserInfo />;
  }

  // OTHERWISE, RENDER UI
  return (
    <div className={`App ${brand.gameStyle} theme-${brand.brand}`}>
      {brand.client === "JBL" && (
        <video
          autoPlay
          muted
          loop
          id="background-video"
          src={backgroundVideo}
        />
      )}

      <body className="landing-bg">{getEllToRender()}</body>
    </div>
  );
}

export default App;
