import BaseUserAvatar from "../../../components/reusable/BaseUserAvatar";
import WinnerBanner from "../assets/winner_banner.png";
import { FC } from "react";
import { ClientProps } from "../../../hooks/useClientData";
import { WinnerResponse } from "../../../types/winnersResponse";
export const WinnerCard: FC<{
  clientData: ClientProps;
  hasBanner?: boolean;
  winnerToUser?: WinnerResponse;
}> = ({ clientData, hasBanner = true, winnerToUser }) => {
  const winner = winnerToUser || clientData.winners?.data?.[0];

  if (!winner) {
    return <></>;
  }

  return (
    <div className="winner">
      <div className="winner__info">
        <div className="winner__avatar">
          <BaseUserAvatar
            logoUrl={winner.userProfileLogoUrl}
            userName={winner.userName}
          />
        </div>

        <p>{winner.userName}</p>

        <span>{winner.points}</span>
      </div>
    </div>
  );
};
